import React from 'react'
import Layout from '../components/layout'
import styled from 'styled-components'
import { FormattedMessage } from 'react-intl'
import { getLanguage } from '../utils/querystring'

const FiveHundredWrapper = styled.div`
  text-align: center;
  margin: 60px auto;
  > h1 {
    font-size: 32px;
    margin-bottom: 30px;
  }
`
const mailLink =
  getLanguage() === 'en'
    ? 'mailto:customerservice@marimekko.com'
    : 'mailto:asiakaspalvelu@marimekko.com'

const NotFoundPage = () => (
  <Layout>
    <FiveHundredWrapper>
      <h1>
        <FormattedMessage id="500_title" />
      </h1>
      <p>
        <FormattedMessage
          id="500_message"
          values={{
            a: chunks => <a href={mailLink}>{chunks}</a>
          }}
        />
      </p>
    </FiveHundredWrapper>
  </Layout>
)

export default NotFoundPage
